import QRCode from "react-qr-code";
import React from "react";

export function getQrCode(selectedQRMode, record, size = 128) {
    let value=selectedQRMode === 'ID' ? record._b64id :
        (record.sname?record.sname:'') + "\t" +
        (record.fname?record.fname:'') + "\t" +
        (record.prename?record.prename:'') + "\t" +
        (record.mname?record.mname:'') + "\t" +
        (record.sufname?record.sufname:'')  + "\t \tt\t" +
        new Date(record.dob).toLocaleDateString('en-gb') + "\t" +
        (!record.gender ? '' : record.gender[0]) + "\t" +
        record._b64id
    return value?  <QRCode value={value} size={size}/>: ''

}