import React from "react";
import {Link} from "react-router-dom";
import { Container, Nav, Navbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
 
// // We import NavLink to utilize the react router.
// import { NavLink } from "react-router-dom";
 
// Here, we display our Navbar
export default function MyNavBar({handleQRModeChange}) {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
      <Navbar.Brand as={Link} to="/">
          <img alt='logo' src="/img/MSK-Doctors-SL-8.png" width="200" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/create">Create Patient</Nav.Link>
          </Nav>
          <ToggleButtonGroup type="radio" name="QRMode" defaultValue={"ID"} onChange={handleQRModeChange}>
            <ToggleButton
              value="ID" id="tbg-radio-1" variant="outline-primary"
            >
              ID
            </ToggleButton>
            <ToggleButton
              value="MRI" id="tbg-radio-2" variant="outline-primary"
            >
              MRI
            </ToggleButton>
          </ToggleButtonGroup>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}